<template>
  <div class="settings-page">
    <profile-form></profile-form>
  </div>
</template>

<script>
import ProfileForm      from "./components/ProfileForm";

export default {
  name: "AccountSettings",
  components: {
    ProfileForm
  }
};
</script>

<style lang="scss" scoped>
.settings-page {
  position: relative;
  min-height: 80vh;
  background-color: #EEF0EB;
}
</style>